import React from 'react'
import { graphql } from 'gatsby'
import SEOTools from '../components/SEOTools'
import { Col, Row } from 'react-bootstrap'
import HeroUnit from '../components/HeroUnit'
import Layout from '../components/Layout'
import FullScreenHeroSection from '../components/pageBuilderComponents/FullScreenHeroSection'
import TableSection from '../components/pageBuilderComponents/TableSection'
import ContactFormSection from '../components/pageBuilderComponents/ContactFormSection'
import RichTextSection from '../components/pageBuilderComponents/RichTextSection'
import TextWithBgSection from '../components/pageBuilderComponents/TextWithBgSection'
import FeaturesSection from '../components/pageBuilderComponents/FeaturesSection'
import RegularHeroSection from '../components/pageBuilderComponents/RegularHeroSection'
import FaqCategorySection from '../components/pageBuilderComponents/FaqCategorySection'
import BlogPostListSection from '../components/pageBuilderComponents/BlogPostListSection'
import { getSrc } from 'gatsby-plugin-image'

/**
 * @param data
 * @param pageContext
 * @returns {JSX.Element}
 * @constructor
 */
export default function SanityPage({ data, pageContext }) {
    const { page } = data

    const firstPageComponentIsAHero = page.pageBuilder[0]?._type.includes('HeroSection')

    let metaImage
    if (firstPageComponentIsAHero) {
        const heroImage = page.pageBuilder[0]?.bgImage
        metaImage = getSrc(heroImage?.asset)
    }

    return (
        <Layout>
            <SEOTools title={page.title} description={page.description} image={metaImage}>
                {!!page.noIndexForRobots && <meta name='robots' content='noindex' />}
            </SEOTools>

            {/* If page does not yet have a hero section, create it */}
            {!firstPageComponentIsAHero && (
                <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay text-white'>
                    <Row className='d-flex align-items-center justify-content-center text-center my-5'>
                        <Col md='8'>
                            <h1>{page.title}</h1>
                        </Col>
                    </Row>
                </HeroUnit>
            )}

            {page.pageBuilder.map((item, i) => {
                const props = {
                    key: item._key,
                    item,
                    isFirstComponent: i === 0,
                }
                switch (item._type) {
                    case 'blogPostListSection':
                        return <BlogPostListSection {...props} lang={pageContext.lang} />
                    case 'featuresSection':
                        return <FeaturesSection {...props} />
                    case 'faqCategorySection':
                        return <FaqCategorySection {...props} lang={pageContext.lang} />
                    case 'fullScreenHeroSection':
                        return <FullScreenHeroSection {...props} />
                    case 'textWithBgSection':
                        return <TextWithBgSection {...props} />
                    case 'tableSection':
                        return <TableSection {...props} />
                    case 'contactFormSection':
                        return <ContactFormSection {...props} />
                    case 'regularHeroSection':
                        return <RegularHeroSection {...props} />
                    case 'richTextSection':
                        return <RichTextSection {...props} />
                    default:
                        return null
                }
            })}
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!, $lang: String!) {
        page: sanityPage(slug: { current: { eq: $slug } }, i18n_lang: { eq: $lang }) {
            title
            description
            noIndexForRobots
            pageBuilder {
                ... on SanityBlogPostListSection {
                    _key
                    _type
                    amountOfPostsToDisplay
                }
                ... on SanityRichTextSection {
                    _key
                    _type
                    _rawBody(resolveReferences: { maxDepth: 1 })
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityTableSection {
                    _key
                    _type
                    chart {
                        rows {
                            _key
                            cells
                        }
                    }
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityContactFormSection {
                    _key
                    _type
                    title
                    subtitle
                    _rawDescription
                    formName {
                        current
                    }
                }
                ... on SanityFaqCategorySection {
                    _key
                    _type
                    title
                    category {
                        # Note: Would be cool to be able to query immediately the faq items belonging to the category
                        _id
                    }
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityFullScreenHeroSection {
                    _key
                    _type
                    _rawContent(resolveReferences: { maxDepth: 1 })
                    justifyContent
                    textAlign
                    bgImage {
                        asset {
                            gatsbyImageData(placeholder: BLURRED, width: 3840)
                        }
                    }
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityRegularHeroSection {
                    _key
                    _type
                    _rawContent(resolveReferences: { maxDepth: 1 })
                    bgImage {
                        asset {
                            gatsbyImageData(placeholder: BLURRED, width: 3840)
                        }
                    }
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityTextWithBgSection {
                    _key
                    _type
                    _rawContent(resolveReferences: { maxDepth: 1 })
                    bgImage {
                        asset {
                            gatsbyImageData(placeholder: BLURRED, width: 3840)
                        }
                    }
                    sectionIdSlug {
                        current
                    }
                }
                ... on SanityFeaturesSection {
                    _key
                    _type
                    title
                    items {
                        icon {
                            name
                            provider
                        }
                        _rawValue
                        _key
                    }
                    sectionIdSlug {
                        current
                    }
                }
            }
        }
        heroBg: file(relativePath: { eq: "sirumobile_frontpage_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`
